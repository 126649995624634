import * as React from 'react';
import './footer.css';
export function PageFooter() {

  return (
    <footer className="Footer">
    <h6 className="Footer-Statement">
      Website created by Brady Dullanty using React, hosted using AWS Amplify</h6>
    </footer>

  );      
}